@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?agbiya');
  src:  url('./assets/fonts/icomoon.eot?agbiya#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?agbiya') format('truetype'),
    url('./assets/fonts/icomoon.woff?agbiya') format('woff'),
    url('./assets/fonts/icomoon.svg?agbiya#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
